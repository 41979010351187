<template>
  <div class="contents">
    <div>
      <el-input
        v-model="keyword"
        placeholder="请输入关键字"
        class="input item"
      ></el-input>
      <el-select
        class="item"
        clearable
        v-model="logType"
        placeholder="操作类型"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span class="item" style="margin-right: 10px">时间范围</span>
      <el-date-picker
        prefix-icon="el-icon-date"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
        v-model="startTime"
        type="datetime"
        placeholder="选择开始日期时间"
      >
      </el-date-picker>
      <span style="padding:4px">-</span>
      <el-date-picker
        class="item"
        prefix-icon="el-icon-date"
        v-model="endTimes"
        value-format="yyyy-MM-dd HH:mm:ss"
        format="yyyy-MM-dd HH:mm:ss"
        type="datetime"
        placeholder="选择结束日期时间"
      >
      </el-date-picker>
      <el-button class="query item" @click="getLogs('search')">查询</el-button>
    </div>
    <div class="table">
      <div class="table">
        <el-table
          stripe
          :header-cell-style="{ background: '#E8EAEF', color: '#3D3D3D' }"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column label="操作人">
            <template slot-scope="scope">{{ scope.row.recordName }}</template>
          </el-table-column>
          <el-table-column prop="createTime" label="操作时间">
            <template slot-scope="scope">
              {{ scope.row.createTime | formattingDate }}
            </template>
          </el-table-column>
          <el-table-column prop="logType" label="操作类型">
            <template slot-scope="scope">
              {{ scope.row.logType | logTypes }}
            </template>
          </el-table-column>
          <el-table-column prop="logDesc" label="显示详情"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                class="particulars"
                type="text"
                @click="particulars(scope.row)"
                >查看详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="app-page-container">
        <span @click="first()">首页</span>
        <el-pagination
          @current-change="handleCurrentChange"
          layout="pager"
          :hide-on-single-page="false"
          :page-size="pageSize"
          :current-page.sync="currentPage"
          :total="recordCount"
        >
        </el-pagination>
        <span @click="last()">尾页</span>
      </div>
    </div>
    <el-dialog
      title="详情"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <el-form label-position="left" label-width="80px">
        <el-form-item label="操作人：">
          <span>{{ particularsData.recordName }}</span>
        </el-form-item>
        <el-form-item label="操作时间：">
          {{ particularsData.createTime | formattingDate }}
        </el-form-item>
        <el-form-item label="操作类型：">
          <span>
            {{ particularsData.logType | logTypes }}
          </span>
        </el-form-item>
        <el-form-item label="显示详情：">
          <span>
            {{ particularsData.logDesc }}
          </span>
        </el-form-item>
      </el-form>
      <!--            <span slot="footer" class="dialog-footer">-->

      <!--            </span>-->
    </el-dialog>
  </div>
</template>

<script>
import { getLog, queryParticulars } from "@/api/systemAdmin/logs.js";
export default {
  name: "logAdmin",
  data() {
    return {
      centerDialogVisible: false,
      tableData: [],
      keyword: "",
      options: [
        {
          value: "1",
          label: "编辑"
        },
        {
          value: "2",
          label: "删除"
        },
        {
          value: "3",
          label: "登录"
        },
        {
          value: "4",
          label: "控制"
        }
      ],
      recordCount: 1,
      startTime: null,
      endTimes: null,
      logType: "",
      currentPage: 1,
      pages: 1,
      pageSize: 12,
      particularsData: ""
    };
  },
  created() {
    this.getLogs();
  },
  filters: {
    logTypes(val) {
      if (val == 1) {
        return "编辑";
      } else if (val == 2) {
        return "删除";
      } else if (val == 3) {
        return "登录";
      } else if (val == 4) {
        return "控制";
      }
    }
  },
  methods: {
    particulars(datas) {
      this.particularsData = datas;
      let data = {
        logId: datas.logId
      };
      queryParticulars(data).then(res => {
        console.log(res.data.data);
        this.particularsData.createTime = res.data.data.createTime;
        this.centerDialogVisible = true;
      });
    },
    //查询
    getLogs(type) {
      if (type === "search") {
        this.pages = 1;
        this.currentPage = 1;
      }
      let data = {
        logType: this.logType,
        logStartTime: this.startTime,
        logEndTime: this.endTimes,
        keyword: this.keyword,
        limit: 12,
        page: this.pages
      };
      getLog(data).then(res => {
        this.recordCount = res.data.data.total;
        this.tableData = res.data.data.list;
        console.log(res);
      });
    },
    //当前页
    handleCurrentChange(val) {
      this.pages = val;
      console.log(`当前页: ${val}`);
      this.getLogs();
    },
    //分页首页
    first() {
      this.currentPage = 1;
      this.pages = 1;
      this.getLogs();
    },
    //分页最后一页
    last() {
      this.currentPage = this.recordCount;
      this.pages = Math.ceil(this.recordCount / 12);
      this.getLogs();
    }
  }
};
</script>

<style scoped>
.particulars {
  font-family: PingFangSC-Regular;
  font-size: 13px;
  color: #6e7790;
  letter-spacing: -0.04px;
}
/deep/ .el-form-item__label {
  width: 100px !important;
}
.input {
  width: 246px;
}
.item {
  margin-right: 20px;
}
.table {
  margin-top: 20px;
}
</style>
