import axios from "../../utils/request";

//获取操作日志
export function getLog(data) {
  return axios({
    url: "/clodchainAM/web/logs/page",
    method: "post",
    data: data
  });
}

//查询日志详情

export function queryParticulars(data) {
  return axios({
    url: "/clodchainAM/web/logs/info",
    method: "get",
    params: data
  });
}
